<template>
  <div class="space-charge">
    <div class="charge-title">
      <span>云空间扩容</span>
      <a-popover>
        <template #content>
          <p>
            1、自动兑换开启后在您云空间到期时可根据您最近一次所兑换的云空间大小自动通过账户金币进行兑换；
          </p>
          <p>
            2、到期后自动兑换时当您账户金币不足时将兑换失败，您需手动进行金币充值并手动兑换；
          </p>
          <p>
            3、若您开启了自动兑换功能，则表示您同意系统通过您账户金币为您兑换云空间。
          </p>
        </template>
        <QuestionCircleOutlined style="color: #ff7b00" />
      </a-popover>
      <span class="date-text">到期后自动兑换</span>
      <a-switch
        v-model:checked="autoExchange"
        checked-children="开"
        un-checked-children="关"
        @change="autoExchangeChange"
      />
    </div>
    <div class="charge-list">
      <template v-for="(item, index) in chargeList" :key="index">
        <div class="charge-item" @click="spaceExchange(item)">
          <span class="charge-space">{{ item.cloudSpaceSize + 'G' }}</span>
          <div class="charge-cost">
            <img src="@/assets/images/play/jinbi.png" alt="" />
            <span>{{ item.goldNumber + '金币/月' }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, createVNode, inject } from 'vue';
import { useStore } from 'vuex';
import {
  Switch as ASwitch,
  Popover as APopover,
  Modal,
  message,
} from 'ant-design-vue';
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import personalApi from '@/api/personal';
import playApi from '@/api/play';

const store = useStore();

const refreshGold = inject('getUserGoldNameDate');
const autoExchange = ref(false);
const chargeList = ref();

const getUserGoldNameDate = inject('getUserGoldNameDate');

const spaceExchange = item => {
  Modal.confirm({
    title: `您确认使用${item.goldNumber}金币兑换${item.cloudSpaceSize}G云空间一个月的使用权？`,
    icon: createVNode(ExclamationCircleOutlined),
    okText: '确认',
    onOk() {
      // todo调取兑换接口，成功并刷新列表
      playApi.exchangeDuration('', { packageId: item.id }).then(res => {
        if (res) {
          getUserGoldNameDate();
          message.success('兑换成功');
          refreshGold();
        } else {
          message.error('兑换失败');
        }
      });
    },
  });
};

const autoExchangeChange = e => {
  playApi
    .autoExchangesUpdate('', {
      userId: store.state.account.user.id,
      autoExchange: e,
    })
    .then(res => {
      if (res) {
        message.success('修改成功');
      } else {
        message.error('修改失败');
      }
    });
};

onMounted(() => {
  personalApi
    .getPackageInfoList('', {
      type: 4,
    })
    .then(res => {
      chargeList.value = res;
    });

  personalApi.getUserSpaceDetail('', {}).then(res => {
    autoExchange.value = res.autoExchange;
  });
});
</script>

<style lang="less" scoped>
.space-charge {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.charge-title {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    // line-height: 24px;

    &:first-child {
      margin-right: auto;
    }
  }

  .date-text {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.charge-list {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px 16px;

  .charge-item {
    width: 144px;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    cursor: pointer;

    .charge-space {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    .charge-cost {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
      }

      span {
        color: #717171;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }
}
</style>
