'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/connections_treasure_box/payment/gold-detail/inviterPageList',
  getPackageInfoList(code, params) {
    return http.get(
      '/connections_treasure_box/payment/package-info/getPackageInfoList',
      { params, code }
    );
  },

  exchangeDuration(code, params) {
    return http.get(
      '/connections_treasure_box/payment/user-package/exchangeDuration',
      { params, code }
    );
  },

  //修改云空间自动兑换
  autoExchangesUpdate(code, data) {
    return http.put(
      '/connections_treasure_box/user/user-cloud-space/updateAutoExchanges',
      data,
      { code }
    );
  },

  //  根据订单获取支付二维码
  createOrder(code, params) {
    return http.get(
      '/connections_treasure_box/payment/user-package/createOrder',
      { params, code }
    );
  },

  // 查询支付状态-微信
  queryOrderStatus(code, params) {
    return http.get(
      '/connections_treasure_box/payment/user-package/queryOrderStatus',
      { params, code }
    );
  },

  // 查询支付状态-支付宝
  queryAliPayOrderStatus(code, params) {
    return http.get(
      '/connections_treasure_box/payment/user-package/queryAliPayOrderStatus',
      { params, code }
    );
  },

  //  收支记录
  goldDetailList(code, data) {
    return http.post(
      '/connections_treasure_box/payment/gold-detail/goldDetailList',
      data,
      {
        code,
      }
    );
  },
  // 邀请好友总数、金币总数、邀请方式接口
  goldOrderGoldDetail(code, id) {
    return http.get(
      `/connections_treasure_box/payment/gold-detail/goldOrderGoldDetail/${id}`,
      { code }
    );
  },

  // 会员个人中心信息
  userGoldNameDate(code, params) {
    return http.get(
      '/connections_treasure_box/payment/gold-detail/userGoldNameDate',
      { params, code }
    );
  },
};
