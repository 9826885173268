<template>
  <div class="issModal" ref="mymodal">
    <a-modal
      :visible="visible"
      title="."
      :mask-closable="false"
      :width="560"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :footer="null"
      :bodyStyle="{ padding: '0px 32px 14px 32px' }"
      :getContainer="() => mymodal"
    >
      <div class="userMessage">
        <div class="avatar">
          <a-avatar :src="setAvatar(userData.avatar)" :size="48" />
        </div>
        <div class="user">
          <div class="userName">{{ userData.userName }}</div>
          <div v-if="activeKeyRight === '1'" class="userBalance">
            您当前套餐于{{ handleData(userData.expirationTime) }}到期
          </div>
          <div v-else class="userBalance">
            账号余额：{{ userData.totalGold }}
          </div>
        </div>
      </div>

      <div class="tabsRight">
        <a-tabs
          v-model:activeKey="activeKeyRight"
          class="left"
          style="overflow: visible"
        >
          <a-tab-pane key="1" tab="订阅套餐">
            <div class="content">
              <div class="title">
                <div class="titleLeft">选择订阅套餐</div>
<!--             连续包季更优惠哦~   -->
                <div class="titleRight"></div>
              </div>
              <!-- 轮播图 -->
              <!-- :autoplay="{
                 delay: 2500,
                 disableOnInteraction: false,
               }"
              :loop="true" -->

              <div class="swiperbox swiper-container">
                <img
                  class="prev-btn"
                  src="@/assets/images/play/swiper-btn.png"
                />
                <img
                  class="next-btn"
                  src="@/assets/images/play/swiper-btn.png"
                />
                <swiper
                  :modules="modules"
                  class="swiper component"
                  :slides-per-view="3"
                  :space-between="30"
                  :slides-per-group="2"
                  style="cursor: default"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                  :navigation="navigation"
                >
                  <swiper-slide
                    v-for="(item, index) in bannerList"
                    :key="item.id"
                    class="swiperItem cursor-p"
                    @click="handleSwiper(item)"
                    :class="{
                      oneClass: index === 0 && showClass,
                      activitClass: item.id === styleId,
                    }"
                  >
                    <div class="lebal">
                      {{ item.packageName }}
                    </div>
                    <div class="jinbi">
                      <!-- <img src="@/assets/images/play/jinbi.png" class="jinbiPng"/> -->
                      <!--  首月  -->
                      <div class="jinbiText">¥</div>
                      <div class="jinbiText2">
                        {{ item.packageMoney / 100 }}
                      </div>
                    </div>
                    <!-- <div class="yuan">次月¥{{ item.packageMoney / 100 }}</div> -->
                  </swiper-slide>
                  <!-- <div
                    class="swiper-pagination"
                    :slot="swiperOptions.pagination"
                  ></div> -->
                </swiper>
              </div>
              <div class="playSele">选择支付方式</div>
              <!--  二维码   -->
              <div class="zhifu">
                <div class="qrCode">
                  <img
                    src="@/assets/images/play/qrCode.png"
                    class="qrBiankuang"
                  />

                  <!-- 这里放置二维码 -->
                  <vue-qr :text="text" :margin="0" :size="120" class="vueQr" />
                  <!-- 订阅 二维码过期，有刷新动作，支付成功有成功提示交互 -->
                  <div class="shuaxin" v-if="showGQ">
                    <div>二维码已过期</div>
                    <div class="shuaxinT cursor-p" @click="handleShuaxin">
                      刷新
                    </div>
                  </div>

                  <div class="chenggong" v-if="showCG">
                    <div>支付成功</div>
                    <div class="chenggongT cursor-p" @click="handleShuaxin">
                      刷新
                    </div>
                  </div>
                </div>

                <div class="playType">
                  <!-- 点击轮播图的金额  -->
                  <div class="jine">
                    ¥
                    <div class="yuan">
                      {{
                        number ? number / 100 : bannerList[0].packageMoney / 100
                      }}
                    </div>
                  </div>

                  <div class="play cursor-p">
<!--                 todo     -->
                    <div
                      class="weichat"
                      @click="handlePlay('weichat')"
                      :class="{
                        isShowWei: !show,
                      }"
                    >
                      <div class="png">
                        <img
                          src="@/assets/images/play/weichatSue.png"
                          class="imgOne"
                          v-if="show"
                        />
                        <img
                          src="@/assets/images/play/weichat.png"
                          class="imgTwo"
                          v-else
                        />
                      </div>
                      微信支付
                    </div>
<!--                 todo    -->
                    <div
                      class="zhi"
                      @click="handlePlay('zhi')"
                      :class="{
                        isShowZhi: !show,
                      }"
                    >
                      <div class="png">
                        <img
                          src="@/assets/images/play/zhi.png"
                          class="imgTwo"
                          v-if="show"
                        />
                        <img
                          src="@/assets/images/play/zhiSue.png"
                          class="imgOne"
                          v-else
                        />
                      </div>
                      支付宝支付
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>

          <a-tab-pane key="2" tab="金币充值" force-render>
            <div class="content tabT">
              <div class="title">
                <div class="titleLeft">选择充值金额</div>
                <div class="titleRight">充的越多送的越多哦~</div>
              </div>

              <div class="swiperbox chongzhi">
                <div
                  v-for="(item, index) in czList"
                  :key="item.id"
                  class="swiperItem cursor-p swiperItemT"
                  @click="handleList(item)"
                  :class="{
                    oneClassT: index === 0 && showClassT,
                    activitClassT: item.id === styleIdT,
                  }"
                >
                  <!-- <div class="lebal">
                    {{ item.lebal }}
                  </div> -->
                  <div class="jinbi jinbiTwo">
                    <img
                      src="@/assets/images/play/jinbi.png"
                      class="jinbiPng"
                    />
                    <div class="jinbiText fs24">
                      {{ item.goldNumber }}
                    </div>
                    <!-- <div class="jinbiText2">
                      {{ item.jinbi.split('¥')[1] }}
                    </div> -->
                  </div>
                  <div class="yuan yuanTwo">¥{{ item.packageMoney / 100 }}</div>
                </div>
              </div>
              <!--         自定义金额   -->
              <div class="zidingyi">
                <div class="zdyL">
                  <a-form-item label="" class="nameClass">
                    <!-- <a-input
                      v-model:value="form.amount"
                      placeholder="自定义金额¥0.00(请输入1-500的整数)"
                      style="width: 166%"
                      @change="validateInput(form.amount)"
                    /> -->
                    <!--              todo 这里报错  customizeList[0].packageMoney -->
                    <a-input-number
                      v-model:value="customizeList[0].packageMoney"
                      placeholder="自定义金额¥0.00(请输入1-500的整数)"
                      style="width: 175%"
                      :min="1"
                      :max="500"
                      :precision="0"
                      :defaultValue="null"
                      @change="validateInput(customizeList[0].packageMoney)"
                    />
                  </a-form-item>
                </div>

                <div class="zdyR">
                  <div class="xianshi">
                    <img
                      src="@/assets/images/play/jinbi.png"
                      class="jinbiPng"
                    />
                    {{ (form.amount * 100) / 5 }}
                  </div>
                  <div
                    class="queren cursor-p"
                    @click="handleSave(customizeList[0])"
                  >
                    确认
                  </div>
                </div>
              </div>

              <div class="playSele">选择支付方式</div>
              <!-- 二维码 -->
              <div class="zhifu">
                <div class="qrCode">
                  <img
                    src="@/assets/images/play/qrCode.png"
                    class="qrBiankuang"
                  />
                  <!-- 这里放置二维码 -->
                  <vue-qr :text="textT" :margin="0" :size="120" class="vueQr" />
                  <!--          二维码过期，有刷新动作，支付成功有成功提示交互      -->
                  <div class="shuaxin" v-if="showGQT">
                    <div>二维码已过期</div>
                    <div class="shuaxinT cursor-p" @click="handleShuaxinT">
                      刷新
                    </div>
                  </div>

                  <div class="chenggong" v-if="showCGT">
                    <div>支付成功</div>
                    <div class="chenggongT cursor-p" @click="handleShuaxinT">
                      刷新
                    </div>
                  </div>
                </div>

                <div class="playType">
                  <!-- 充值金额 -->
                  <div class="jine">
                    ¥
                    <div class="yuan">
                      {{
                        numberT ? numberT / 100 : czList[0].packageMoney / 100
                      }}
                    </div>
                  </div>

                  <div class="play cursor-p">
                    <div
                      class="weichat"
                      @click="handlePlay('weichat')"
                      :class="{
                        isShowWei: !show,
                      }"
                    >
                      <div class="png">
                        <img
                          src="@/assets/images/play/weichatSue.png"
                          class="imgOne"
                          v-if="show"
                        />
                        <img
                          src="@/assets/images/play/weichat.png"
                          class="imgTwo"
                          v-else
                        />
                      </div>
                      微信支付
                    </div>
                    <div
                      class="zhi"
                      @click="handlePlay('zhi')"
                      :class="{
                        isShowZhi: !show,
                      }"
                    >
                      <div class="png">
                        <img
                          src="@/assets/images/play/zhi.png"
                          class="imgTwo"
                          v-if="show"
                        />
                        <img
                          src="@/assets/images/play/zhiSue.png"
                          class="imgOne"
                          v-else
                        />
                      </div>
                      支付宝支付
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>

          <a-tab-pane key="3" tab="云空间">
            <spaceCharge></spaceCharge>
          </a-tab-pane>
        </a-tabs>
        <!-- <div class="butS">充值</div>-->
      </div>
    </a-modal>
  </div>

  <!--  </div>-->
</template>
<script>
import {
  reactive,
  toRaw,
  toRefs,
  ref,
  onMounted,
  watch,
  defineExpose,
} from 'vue';
import { message, Tabs, Avatar, InputNumber } from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import { useStore } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';
import spaceCharge from './components/spaceCharge.vue';

// import { Navigation } from 'swiper/modules/navigation.mjs';
// import { Pagination } from 'swiper/modules/pagination.mjs';
// import { Autoplay, Navigation, Pagination, A11y } from 'swiper'; // 分页器
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/swiper.css';
import 'swiper/swiper-bundle.css';
import VueQr from 'vue-qr/src';
import { setAvatar } from '@/utils';
import playApi from '@/api/play';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    // ACarousel: Carousel,
    Swiper,
    SwiperSlide,
    VueQr,
    AAvatar: Avatar,
    AInputNumber: InputNumber,
    spaceCharge,
  },
  props: {
    visible: Boolean,
    init: Object,
    activeItem: Number,
  },
  setup(props, context) {
    // const router = useRouter();
    const mymodal = ref();
    const store = useStore();
    const userId = store.state.account.user.id;
    const form = reactive({
      amount: null,
    });
    const rechargeType = ref(0);

    // const modules = [Autoplay, Pagination, Navigation, A11y];
    const modules = [Navigation];
    const state = reactive({
      styleId: '',
      showClass: true,
      showActivitClass: true,
      styleIdT: '',
      showClassT: true,
      number: '',
      textT: '',
      text: '',

      numberT: '',
      // 显示订阅过期
      showGQ: false,
      showCG: false,

      // 显示充值过期
      showGQT: false,
      showCGT: false,

      // 用户信息 local.get('userData')
      userData: {},

      show: true,
      activeKeyRight: '1',
      groupsList: [],

      swiperOptions: {
        loop: true,
        slidesPerView: 3, // 多列——一屏显示3列
        slidesPerGroup: 2, //多列分组，一次滚动一组——分2组
        spaceBetween: 50,

        slideClass: 'custom-slide-class',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
        },
      },

      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },

      loading: false,

      bannerList: [],
      czList: [],
      customizeList: [],

      //  订阅定时器
      timer: null,
      timerT: null,
      //   查询订阅订单状态参数
      aa: '',
      //   订阅刷新
      sxIds: '',

      //   金币充值
      bb: '',
      sxIdsT: '',
      money: '',
    });

    // watch(
    //     () => props.init,
    //     init => Object.assign(form, init)
    // );

    contactAll.getContactGroupsList('', userId).then(res => {
      if (res) {
        state.groupsList = res;
      }
    });

    // 会员个人中心信息
    const getUserGoldNameDate = () => {
      playApi.userGoldNameDate('', {}).then(res => {
        if (res) {
          state.userData = res;
        }
      });
    };

    const handleCancel = () => {
      // 关闭弹窗清空订阅 id 和金额
      state.styleId = '';
      state.number = '';
      //  金币充值 id 和金额
      state.styleIdT = '';
      state.numberT = '';
      // 清除定时器
      clearInterval(state.timer);
      clearInterval(state.timerT);
      // 关闭过期和成功弹窗
      state.showGQ = false;
      state.showCG = false;

      state.showGQT = false;
      state.showCGT = false;

      state.activeKeyRight = '1';
      context.emit('fnOk', false);
    };

    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };

    // todo 切换支付方式
    const handlePlay = type => {
      console.log('type', type);
      // 需要改变二维码链接
      if (type === 'zhi') {
        state.show = false;
        rechargeType.value = 1;
      } else {
        state.show = true;
        rechargeType.value = 0;
      }
      console.log('state.show', state.show);

      if (state.activeKeyRight === '1') {
        clearInterval(state.timer);
        handleShuaxin();
      }

      if (state.activeKeyRight === '2') {
        clearInterval(state.timerT);
        handleShuaxinT();
      }

    };

    // 订阅套餐刷新
    const handleShuaxin = () => {
      // 获取订单信息
      getCreateOrder(userId, state.sxIds, 0);
      //   关闭刷新弹窗
      state.showGQ = false;
      state.showCG = false;
    };

    // 金币充值刷新
    const handleShuaxinT = () => {
      console.log('shuaxin');
      // 充值的刷新分： 固定金额刷新和自定义金额刷新

      // 获取订单信息
      if (state.money) {
        getCreateOrder(userId, state.sxIdsT, 1, state.money);
      } else {
        getCreateOrder(userId, state.sxIdsT, 1);
      }

      //   关闭刷新弹窗
      state.showGQT = false;
      state.showCGT = false;
    };

    // 未支付状态处于五分钟，没有拿到支付成功信息，那么弹窗提示二维码过期，请刷新
    // 点击刷新，调取订单信息（这时需要知道当前选择的是那个套餐）
    // 如果支付成功后，那么弹窗提示支付成功，请刷新，点击刷新逻辑同上

    // 查询订单状态
    const getQueryOrderStatus = (outTradeNo, type) => {
      if (rechargeType.value) {
        //   根据 outTradeNo订单号
        playApi
          .queryAliPayOrderStatus('', { outTradeNo: outTradeNo })
          .then(res => {
            if (res) {
              // console.log('支付状态', res);
              if (res.tradeState === 'NOTPAY') {
                console.log('NOTPAY NOTPAY');
              } else if (res.tradeState === 'TRADE_SUCCESS') {
                //   支付成功
                // console.log('SUCCESS');

                if (type === 0) {
                  state.showCG = true;
                  // 获取用户套餐信息
                  getUserGoldNameDate();
                  clearInterval(state.timer);
                } else {
                  state.showCGT = true;
                  // 获取用户套餐信息
                  getUserGoldNameDate();
                  clearInterval(state.timerT);
                }
              }
            }
          });
      } else {
        //   根据 outTradeNo订单号
        playApi.queryOrderStatus('', { outTradeNo: outTradeNo }).then(res => {
          if (res) {
            // console.log('支付状态', res);
            if (res.tradeState === 'NOTPAY') {
              console.log('NOTPAY NOTPAY');
            } else if (res.tradeState === 'SUCCESS') {
              //   支付成功
              // console.log('SUCCESS');

              if (type === 0) {
                state.showCG = true;
                // 获取用户套餐信息
                getUserGoldNameDate();
                clearInterval(state.timer);
              } else {
                state.showCGT = true;
                // 获取用户套餐信息
                getUserGoldNameDate();
                clearInterval(state.timerT);
              }
            }
          }
        });
      }
    };
    // 获取订单信息
    const getCreateOrder = (userId, id, type, money) => {
      playApi
        .createOrder('', {
          userId: userId,
          packageId: id,
          money: money,
          payMethod: rechargeType.value ? 'ZFB' : 'WX',
        })
        .then(res => {
          if (res) {
            // console.log('res66订单信息', res);
            // state.bannerList = res
            // 订阅套餐
            if (type === 0) {
              state.text = res.qrCode;
              // 查询订单状态 5分钟
              // 写一个5分钟的定时器每一秒钟去调取 getQueryOrderStatus 函数，
              // getQueryOrderStatus函数做的事情是调取api接口，根据api接口返回的状态来关闭定时器
              // api接口一共返回这三种状态： SUCCESS：支付成功   NOTPAY：未支付    CLOSED：已关闭
              // 如果五分钟到了 api都是返回 NOTPAY：未支付，则返回： showGQ = true，并且关闭定时器
              // 如果在在5分钟内 api都是返回 SUCCESS：支付成功， 则返回：showCG = true，并且关闭定时器
              // 请给出js代码实现方案

              // 打开弹窗默认选择第一个订单的订单号信息
              state.aa = res.outTradeNo;
              getQueryOrderStatus(res.outTradeNo, 0);

              //   切换订阅套餐&&弹窗开启时执行
              if (props.visible) {
                clearInterval(state.timer);
                // console.log('开启 切换');
                state.timer = setInterval(() => {
                  console.log('tab1');
                  getQueryOrderStatus(state.aa, 0);
                }, 1000);

                setTimeout(() => {
                  clearInterval(state.timer);
                  console.log('5分钟过去了 tab1');
                  // 开启二维码过期状态
                  state.showGQ = true;

                  //  300000
                }, 300000);
              }
            } else {
              // 金币充值
              state.textT = res.qrCode;

              // 打开弹窗默认选择第一个订单的订单号信息
              state.bb = res.outTradeNo;
              // // 查询订单状态
              getQueryOrderStatus(res.outTradeNo, 1);

              //   切换订阅套餐&&弹窗开启时执行
              if (props.visible) {
                clearInterval(state.timerT);
                // console.log('开启 切换 充值');
                state.timerT = setInterval(() => {
                  console.log('tab2');
                  getQueryOrderStatus(res.outTradeNo, 1);
                }, 1000);

                setTimeout(() => {
                  clearInterval(state.timerT);
                  console.log('5分钟过去了 tab2');
                  // 开启二维码过期状态
                  state.showGQT = true;

                  //  300000
                }, 300000);

              }
            }
          }
        });
    };

    // 订阅套餐 数组
    // 默认第一个选择，点击时会拿到点击时的对应订单, 弹窗打开才获取
    playApi.getPackageInfoList('', { type: 0 }).then(res => {
      if (res) {
        state.bannerList = res;
        // 订阅套餐默认第一个选择，存起来给刷新用
        state.sxIds = state.bannerList[0].id;
        // getCreateOrder(userId, state.bannerList[0].id, 0);
      }
    });

    // 金币充值 todo
    const getDataT = () => {
      playApi.getPackageInfoList('', { type: 1 }).then(res => {
        if (res) {
          // console.log('res cz', res);
          res.map(item => {
            if (item.packageContract === 0) {
              state.czList.push(item);
              // 获取订单信息
              // getCreateOrder(userId, state.czList[0].id, 1);
              state.sxIdsT = state.czList[0].id;
            } else if (item.packageContract === 2) {
              state.customizeList.push(item);
            } else {
              state.czList.push(item);
            }
          });
        }
      });
    };

    // 监听调用：仅首次弹窗打开时调用,订阅套餐默认第一个创建订单
    watch(
      () => props.visible,
      value => {
        // console.log('仅首次弹窗打开时调用', value);
        if (value) {
          clearInterval(state.timer);
          state.showClass = true;
          getCreateOrder(userId, state.bannerList[0].id, 0);

          state.showGQ = false;
          state.showCG = false;

          state.showGQT = false;
          state.showCGT = false;

          // state.timer = setInterval(() => {
          //   console.log('tab1');
          //   getQueryOrderStatus(state.aa, 0);
          // }, 1000);
          //
          // setTimeout(() => {
          //   clearInterval(state.timer);
          //   console.log('5分钟过去了 tab1');
          //   // 开启二维码过期状态
          //   state.showGQ = true;
          //
          //   //  300000
          // }, 300000);

        }
      }
    );
    // 监听 activeKeyRight 是 1 还是2
    watch(
      () => state.activeKeyRight,
      value => {
        if (value === '1') {
          // console.log('value activeKeyRight');
          // 切换tab清除另一个定时器
          clearInterval(state.timerT);

          state.showGQ = false;
          state.showClass = true;
          state.styleId = '';
          state.number = state.bannerList[0].packageMoney;
          // 获取订单信息
          getCreateOrder(userId, state.bannerList[0].id, 0);
        } else if (value === '2') {
          clearInterval(state.timer);
          state.showGQT = false;
          state.styleIdT = '';
          state.showClassT = true;

          state.numberT = state.czList[0].packageMoney;
          // state.showClassT = true;
          // 获取订单信息
          getCreateOrder(userId, state.czList[0].id, 1);
        } else {
          clearInterval(state.timer);
          clearInterval(state.timerT);

        }
      }
    );

    watch(
      () => props.activeItem,
      value => {
        if (value === '3') {
          state.activeKeyRight = '3';
        } else {
          state.activeKeyRight = '1';
        }
      }
    );

    defineExpose({ getUserGoldNameDate });

    onMounted(() => {
      getUserGoldNameDate();
      getDataT();
    });

    return {
      ...toRefs(state),
      form,
      getDataT,
      handlePlay,
      mymodal,
      modules,
      Navigation,
      // Pagination,
      handleShuaxin,
      handleShuaxinT,
      setAvatar,
      onSlideChange,
      onSwiper,
      handleCancel,
      getUserGoldNameDate,
      validateInput(value) {
        clearInterval(state.timerT);
        clearInterval(state.timer);
        // 将字符串转换为数字
        // let number = parseInt(value, 10);
        clearInterval(state.timerT);
        if (value === null || value === '') {
          console.log('222');
          form.amount = 0;
        } else {
          // 检查是否在1到500的范围内
          if (value < 1 || value > 500) {
            // 不在范围内，清空输入框或采取其他处理方式
            form.amount = 0;
            state.showClassT = false;
            return message.error('请输入1-500的整数');
          } else {
            state.showClassT = false;
            form.amount = value;
          }
        }
      },
      handleSwiper(item) {
        // 需要改变二维码链接
        state.styleId = item.id;
        state.showClass = false;
        state.number = item.packageMoney;

        //  切换时关闭定时器
        clearInterval(state.timer);

        // 点击该条数据：获取订单信息
        getCreateOrder(userId, item.id, 0);
        // 点击该条数据：获取订单信息，存起来给刷新用
        state.sxIds = item.id;

        // 切换时关闭二维码弹窗
        state.showGQ = false;
      },
      handleList(item) {
        console.log('金币充值', item);
        // 需要改变二维码链接
        state.styleIdT = item.id;
        state.showClassT = false;
        state.numberT = item.packageMoney;
        // 输入完自定义金额后，再次点击固定金额，需求清空自定义金额 customizeList[0].packageMoney
        state.customizeList[0].packageMoney = null;

        //  切换时关闭定时器
        clearInterval(state.timerT);

        // 点击该条数据：获取订单信息
        getCreateOrder(userId, item.id, 1);
        // 点击该条数据：获取订单信息，存起来给刷新用
        state.sxIdsT = item.id;

        // 切换时关闭二维码弹窗
        state.showGQT = false;
      },
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        state.loading = true;
        contactAll.saveUserInfo('', toRaw(form)).then(() => {
          state.loading = false;
          message.success('操作成功');
          context.emit('fnOk', false);
          // router.push(`/contacts/${res.id}`);
        });
      },
      // 确认自定义金额
      handleSave(list) {
        // 需要对输入框金额做判空
        console.log('list', list);

        // todo 改变自定义金额
        if (list.packageMoney) {
          // 关闭二维码过期状态
          state.showGQT = false;
          // 自定义id
          state.sxIdsT = list.id;
          state.money = list.packageMoney;
          state.numberT = list.packageMoney * 100;

          // 获取订单信息
          getCreateOrder(userId, list.id, 1, list.packageMoney);
        } else {
          message.error('请输入自定义金额');
        }

        // state.textT =
        //   'https://ismartek-app-uat.issmart.com.cn/form/#/0000/1184107600224977057/1184107597754531873';
        // 取消金额选择效果
        // state.showClassT = false
      },
      handleData(data) {
        let dateObj = new Date(data);
        if (!isNaN(dateObj.getTime())) {
          let d =
            dateObj.getFullYear() +
            '年' +
            (dateObj.getMonth() + 1) +
            '月' +
            dateObj.getDate() +
            '日';
          return d;
        } else {
          return '';
        }
      },
    };
  },
};
</script>
<style lang="less" scoped>
.issModal /deep/ .ant-modal-header {
  padding: 25px 24px;
  background: linear-gradient(270deg, #ff7b00 0%, #ffc236 100%);
  box-shadow: inset 0px 7px 5px 0px rgba(255, 255, 255, 0.3),
    inset 0px 2px 2px 0px rgba(255, 255, 255, 0.6);
  height: 72px;
}

.issModal /deep/ .ant-modal-close {
  color: #ffff;
}

.userMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  top: 3px;
  left: 24px;

  .avatar {
    margin-right: 24px;
  }
  .user {
    color: #ffffff;
    .userName {
      font-size: 24px;
      font-weight: 500;
    }

    .userBalance {
      font-size: 14px;
    }
  }
}

.tabsRight {
  min-height: 550px;
}

.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0 0px;
  border-bottom: 0.5px solid #eeeeee;
}

.content {
  // max-height: 500px;
  overflow: visible;
  // overflow-y: auto;
}
.tabT {
  padding-right: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 1px solid #eeeeee;
  .itemOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  // .itemTwo {
  // }
}
.butS {
  position: absolute;
  top: 66px;
  right: 30px;
  padding: 4px 16px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  //padding-right: 24px;

  //.titleLeft {
  //}
  .titleRight {
    font-size: 14px;
    text-align: right;
    color: #999999;
  }
}
// 轮播图
.classOne {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  background: #ffffff;
  padding-top: 30px;
  text-align: left;

  .classOne1,
  .classOne2,
  .classOne3 {
    width: 144px;
    height: 88px;
    border-radius: 8px;
    opacity: 1;
    background: #fffcf0;
    border: 1px solid #ffb11d;
    display: inline-block;
  }
  .classOne2 {
    margin: 0px 16px;
  }
}

.myCarousel /deep/ .custom-slick-arrow.slick-arrow.slick-next {
  color: #767d89;
}
//.myCarousel /deep/.slick-slide {
//  background: #FFFFFF;
//}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.chongzhi {
  display: flex;
  justify-content: flex-start;
  //justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

// todo

.swiperItem {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 11px 10px 11px 20px;
  margin-top: 12px;
  width: 144px;
  height: 116px;

  .lebal {
    font-size: 20px;
  }
  .jinbi {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    color: #c37101;
    .jinbiPng {
      width: 23px;
      height: 23px;
    }

    // .jinbiPng {
    // }
    .jinbiText {
      font-size: 14px;
      margin-left: 2px;
    }
    .jinbiText2 {
      font-size: 24px;
      font-weight: 400;
      //padding-bottom: 7px;
    }
  }
  .jinbiTwo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #333333;

    .fs24 {
      font-size: 24px;
    }
  }

  .yuan {
    font-size: 14px;
    color: #666666;
  }
  .yuanTwo {
    margin-left: 18px;
  }
}

.swiperItemT {
  //margin-right: 0px !important;
  height: 88px;
  margin-right: 14px !important;
}
.playSele {
  margin: 32px 0px;
  font-size: 14px;
}

.zhifu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  .qrCode {
    width: 42%;
    //background-image: url('../../assets/images/play/qrCode.png');
    //background-size: cover;
    //height: 240px;
    position: relative;
    margin-left: 20px;
    .qrBiankuang {
      width: 147px;
    }

    .vueQr {
      position: absolute;
      left: 13px;
      top: 12px;
      width: 120px;
    }
    // .shuaxin {
    //   position: absolute;
    //   left: 14px;
    //   top: 70px;
    //   z-index: 1;
    // }

    // .qrBiankuang {
    // }
  }
  .playType {
    width: 50%;
    margin-right: 25px;

    .jine {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      font-size: 20px;

      .yuan {
        font-size: 36px;
        font-weight: 500;
      }
    }

    .play {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f3f5f7;
      border-radius: 4px;
      padding: 2px;
      margin-top: 30px;

      .weichat {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 17px;

        .png {
          margin-right: 4px;
        }
      }

      .zhi {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #f3f5f7;
        border-radius: 4px;
        color: #999999;
        padding: 8px;
        font-size: 17px;

        .png {
          margin-right: 4px;
        }
      }
    }
  }
}

.isShowZhi {
  color: #333333 !important;
  background: #ffffff !important;
  border-radius: 4px;
  padding: 8px;
}

.isShowWei {
  background: #f3f5f7 !important;
  border-radius: 4px;
  color: #999999 !important;
  padding: 8px;
}
.activitClass {
  border: 1px solid #ffb11d;
  background: #fffcf0;
}
.oneClass {
  border: 1px solid #ffb11d;
  background: #fffcf0;
}

.activitClassT {
  border: 1px solid #ffb11d;
  background: #fffcf0;
}
.oneClassT {
  border: 1px solid #ffb11d;
  background: #fffcf0;
}

.zidingyi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  .zdyL {
    width: 37%;
    // .nameClass {
    // }
  }
  .zdyR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 37%;
    .xianshi {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0px 8px 0px 13px;
      text-align: left;
      .jinbiPng {
        margin-right: 4px;
      }
    }

    .queren {
      background: #ff8f1f;
      color: #ffffff;
      font-size: 14px;
      padding: 5px 22px;
      border-radius: 4px;
    }
  }
}

.shuaxin {
  position: absolute;
  left: 13px;
  top: 12px;
  width: 120px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  height: 120px;
  text-align: center;
  padding-top: 30px;

  .shuaxinT {
    border: 1px solid #ffffff;
    margin: 10px 30px;
    padding: 2px 0px;
  }
}
.chenggong {
  position: absolute;
  left: 13px;
  top: 12px;
  width: 120px;
  height: 120px;
  text-align: center;
  padding-top: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  height: 120px;
  .chenggongT {
    border: 1px solid #ffffff;
    margin: 10px 30px;
    padding: 2px 0px;
  }
}

.swiperbox {
  width: 100%;
  position: relative;
  overflow: visible !important;

  .prev-btn,
  .next-btn {
    width: 16px;
    height: 24px;
    object-fit: cover;
    position: absolute;
    top: 50%;

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .prev-btn {
    left: -8px;
    transform: translate(-100%, -50%) rotate(180deg);
  }

  .next-btn {
    right: -8px;
    transform: translate(100%, -50%);
  }
}

/deep/ .ant-modal-content {
  overflow: hidden !important;
}
</style>
